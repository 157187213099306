<template>
  <div class="wrap">
    <div class="banner">
      <div class="text animated fadeIn">
        <p class="icon"></p>
        <p class="h2">病区床旁自动输液站系统</p>
      </div>
    </div>
    <div class="content">
      <div class="en t2"></div>
      <div class="inner product">
        <h2>产品<em>介绍</em></h2>
        <div class="details">
          <div class="img">
            <img :src="imgUrl.img1"
                 alt="">
          </div>
          <div class="text">
            <dl>
              <dt>精准安全</dt>
              <dd>采用紧凑的蠕动泵结构设计，保证高度的可靠和安全。</dd>
              <dd>自动泵门与自动止液夹。</dd>
              <dd>双CPU设计、保证了实时控制与数据的可靠性。</dd>
            </dl>
            <dl>
              <dt>轻巧易用</dt>
              <dd>触摸屏操作，快速响应各种设置需求。</dd>
              <dd>机身轻巧，方便携带、转运。</dd>
              <dd>多种输液模式：满足各种临床实用环境输液。</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="content bg">
      <div class="en t1"></div>
      <div class="inner product">
        <h2>产品<em>特征</em></h2>
        <div class="list">
          <ul>
            <li>
              <em class="p1"></em>
              <h3>患者识别</h3>
            </li>
            <li>
              <em class="p2"></em>
              <h3>输液自动化</h3>
            </li>
            <li>
              <em class="p3"></em>
              <h3>输液意外防控</h3>
            </li>
            <li>
              <em class="p4"></em>
              <h3>输液自动提示</h3>
            </li>
            <li>
              <em class="p5"></em>
              <h3>自动液监控</h3>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import getAliyunImg from '../../utils/aliyun'

export default {
  name: 'doctor',
  created () {
    this.imgUrl.banner = getAliyunImg.signatureUrl('ytwd/gw/infusion-banner.png')
    this.imgUrl.img1 = getAliyunImg.signatureUrl('ytwd/gw/infusion.png')
  },
  data () {
    return {
      imgUrl: {
        banner: '',
        img1: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./css/style.scss";
.banner {
  background-image: url("../../assets/images/infusion-banner.png");
  .icon {
    background-image: url("../../assets/images/infusion-icon.png");
  }
}
.content {
  .product .details {
    align-items: center;
    flex-direction: column;
    .text {
      width: 100%;
      padding: 75px 0;
      display: flex;
      height: auto;
      // align-self: flex-start;
      dl {
        &:last-child {
          border-left: 2px solid #d2d2d2;
          padding-left: 136px;
        }
        width: 50%;
        padding: 48px 85px 80px;
        dt {
          font-size: 24px;
          color: #434343;
          margin-bottom: 37px;
          text-align: center;
        }
        dd {
          font-size: 16px;
          color: #6a6a6a;
          margin-top: 20px;
          position: relative;
          padding-left: 30px;
          &:before {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            background: #6a6a6a;
            border-radius: 50%;
            position: absolute;
            top: 4px;
            left: 0;
          }
        }
      }
    }
    .img {
      // height: 535px;
      background: none;
      width: 100%;
    }
  }
}
</style>
